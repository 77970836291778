/* @@@@@@( Main Content )@@@@@@ */
.main {
  background: var(white);
  left: 300px;
  min-height: 100vh;
  position: absolute;
  transition: 0.5s;
  width: calc(100% - 300px);
}

.main.active {
  left: 80px;
  width: calc(100% - 80px);
}

/* @@@@@@( Main Content Cards )@@@@@@ */
.cardBox {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: repeat(4, 1fr);
  padding: 20px;
  position: relative;
  width: 100%;
}

.cardBox .card {
  background: var(--white);
  border-radius: 20px;
  box-shadow: 0 7px 25px rgb(0 0 0 / 8%);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 30px;
  position: relative;
}

.cardBox .card .numbers {
  color: var(--blue);
  font-size: 2.5rem;
  font-weight: 500;
  position: relative;
}

.cardBox .card .cardName {
  color: var(--black2);
  font-size: 1.1rem;
  margin-top: 5px;
}

.cardBox .card .iconBox {
  color: var(--black2);
  font-size: 3.5rem;
}

.cardBox .card:hover {
  background: var(--blue);
}

.cardBox .card:hover .numbers,
.cardBox .card:hover .cardName,
.cardBox .card:hover .iconBox {
  color: var(--white);
}

/* @@@@@@( Orders Detail List )@@@@@@ */
.chartBox {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 2fr;
  padding: 20px;
  position: relative;
  width: 100%;
}

.chartBox .chart {
  background: var(--white);
  border: 1px solid var(--black2);
  border-radius: 20px;
  box-shadow: 0 7px 25px rgb(0 0 0 / 8%);
  padding: 20px;
  position: relative;
  width: 100%;
}

/* @@@@@@( Orders Detail List )@@@@@@ */
.details {
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 2fr 1fr;
  padding: 20px;
  position: relative;
  width: 100%;
}

.details .recentOrders {
  background: var(--white);
  border-radius: 20px;
  box-shadow: 0 7px 25px rgb(0 0 0 / 8%);
  display: grid;
  min-height: 500px;
  padding: 20px;
  position: relative;
}

.cardHeader {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}

.cardHeader h2 {
  color: var(--blue);
  font-weight: 600;
}

.details .btn {
  background: var(--blue);
  border-radius: 6px;
  color: var(--white);
  padding: 5px 10px;
  position: relative;
  text-decoration: none;
}

.details table {
  border-collapse: collapse;
  margin-top: 10px;
  width: 100%;
}

.details table thead td {
  font-weight: 600;
}

.details .recentOrders table tr {
  border-bottom: 1px solid rgb(0 0 0 / 10%);
  color: var(--black1);
}

.details .recentOrders table tr:last-child {
  border-bottom: none;
}

.details .recentOrders table tbody tr:hover {
  background: var(--blue);
  color: var(--white);
}

.recentCustomers table tr td {
  padding: 12px 10px;
}

.details .recentOrders table tr td {
  padding: 10px;
}

.details .recentOrders table tr td:last-child {
  text-align: end;
}

.details .recentOrders table tr td:nth-child(2) {
  text-align: end;
}

.details .recentOrders table tr td:nth-child(3) {
  text-align: center;
}

.status.delivered {
  background: #8de02c;
  border-radius: 4px;
  color: var(--white);
  font-size: 14px;
  font-weight: 500;
  padding: 2px 4px;
}

.status.pending {
  background: #e9b10a;
  border-radius: 4px;
  color: var(--white);
  font-size: 14px;
  font-weight: 500;
  padding: 2px 4px;
}

.status.return {
  background: #f00;
  border-radius: 4px;
  color: var(--white);
  font-size: 14px;
  font-weight: 500;
  padding: 2px 4px;
}

.status.inProgress {
  background: #1775ce;
  border-radius: 4px;
  color: var(--white);
  font-size: 14px;
  font-weight: 500;
  padding: 2px 4px;
}

/* @@@@@@( Recent Customers )@@@@@@ */
.recentCustomers {
  background: var(--white);
  border-radius: 20px;
  box-shadow: 0 7px 25px rgb(0 0 0 / 8%);
  display: grid;
  min-height: 500px;
  padding: 20px;
  position: relative;
}

.recentCustomers .imageBox {
  border-radius: 50px;
  height: 40px;
  overflow: hidden;
  position: relative;
  width: 40px;
}

.recentCustomers .imageBox img {
  height: 100%;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}

.recentCustomers table tr td h4 {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2rem;
}

.recentCustomers table tr td span {
  color: var(--black2);
  font-size: 14px;
}

.recentCustomers table tr:hover td span {
  color: var(--white);
}

/* @@@@@@@@@@@@@@@@@@@@@@@@@( Responsive Design )@@@@@@@@@@@@@@@@@@@@@@@@@ */
@media (width <= 991px) {
  .chartBox {
    grid-template-columns: 1fr;
    height: auto;
  }

  .navigation {
    left: -300px;
  }

  .navigation.active {
    left: 0;
    width: 300px;
  }

  .main {
    left: 0;
    width: 100%;
  }

  .main.active {
    left: 300px;
  }

  .cardBox {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (width <= 768px) {
  .details {
    grid-template-columns: 1fr;
  }

  .recentOrders {
    overflow-x: auto;
  }

  .status.inProgress {
    white-space: nowrap;
  }
}

@media (width <= 480px) {
  .cardBox {
    grid-template-columns: repeat(1, 1fr);
  }

  .cardHeader h2 {
    font-size: 20px;
  }

  .user {
    min-width: 40px;
  }

  .navigation {
    left: -100%;
    width: 100%;
    z-index: 1000;
  }

  .navigation.active {
    left: 0;
    width: 100%;
  }

  .toggle {
    z-index: 10001;
  }

  .main.active .toggle {
    color: var(--white);
    left: initial;
    position: fixed;
    right: 0;
  }
}
